import React from 'react'
import _ from 'lodash';
import { FloatValues, getCurrencySign, storeAssigned } from '../../../services/Methods/normalMethods';
import ButtonComponent from '../../../Inputs/ButtonComponent';
import { Print } from '@mui/icons-material';

const MainThankYou = ({ data, autoshiporder }) => {
    const CURRENT_STORE = _.upperCase(storeAssigned()) || 'US';

    const HEADING_OBJ = (row) => ({
        'Order Number': row?.orderId,
        'Date': row?.orderDate?.split('T')[0],
        'Email': row?.emailAddress,
        'Total': `${getCurrencySign(CURRENT_STORE)}${FloatValues(row?.orderTotal)}`,
        'Payment Method': 'Credit Card'
    }
    );

    const handlePrint = () => {
        window.print();
    };
    const isDataLoaded = data && data.length > 0;


    return (
        <>

            <div className='thanks-section'>
                <div className='container'>
                    <h2>Thank you for your order
                        {isDataLoaded && (
                            <span className='align-middle sm:ml-[50px] ml-[20px] sm:h-[40px] h-[30px] sm:w-[40px] w-[30px] inline-block cursor-pointer' onClick={handlePrint}>
                                <Print style={{ width: '100%', height: '100%', verticalAlign: 'middle' }} />
                            </span>
                        )}
                    </h2>
                    {_.map(data, (row) => (
                        <div className="thanks-order" key={row?.orderId + "_hello"}>
                            {_.map(_.keys(HEADING_OBJ(row)), (val) => (
                                <div className='order-number' key={val}>
                                    <h3>{val}:</h3>
                                    <div className='tanks-price'>{HEADING_OBJ(row)?.[val]}</div>
                                </div>
                            ))}


                        </div>
                    ))}
                    <div className='table-data'>
                        {_.map(data, (row) => (
                            <React.Fragment key={row?.orderId + "_normal"}>
                                <h3><strong style={{ fontSize: "40px" }}>Order Details</strong></h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>PRODUCT</th>
                                            <th>QTY.</th>
                                            <th>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {row?.details?.map((data, index) => {
                                            return (
                                                <tr key={index + 1}>
                                                    <td>{data?.itemDescr}</td>
                                                    <td>{data?.quantity}</td>
                                                    <td>{getCurrencySign(CURRENT_STORE)}{FloatValues(data?.itemPriceTotal)}</td>
                                                </tr>
                                            )
                                        })}
                                        <tr className='empty-td'>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr className='empty-td'>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>

                                        <tr className='subtotal-td'>
                                            <td><strong>Subtotal</strong></td>
                                            <td></td>
                                            <td>{getCurrencySign(CURRENT_STORE)}{FloatValues(row?.subTotal)}</td>

                                        </tr>
                                        <tr className='subtotal-td'>
                                            <td>Tax</td>
                                            <td></td>
                                            <td>{getCurrencySign(CURRENT_STORE)}{FloatValues(row?.taxTotal)}</td>
                                        </tr>
                                        <tr className='subtotal-td'>
                                            <td>Shipping</td>
                                            <td></td>
                                            <td>{getCurrencySign(CURRENT_STORE)}{FloatValues(row?.shippingTotal)}</td>
                                        </tr>
                                        {row?.payments?.[1]?.amount && <tr className='subtotal-td'>
                                            <td>Points Redeemed</td>
                                            <td></td>
                                            <td>{getCurrencySign(CURRENT_STORE)}{FloatValues(row?.payments?.[1]?.amount)}</td>
                                        </tr>}
                                        <tr className='subtotal-td'>
                                            <td>Total</td>
                                            <td></td>
                                            <td>{getCurrencySign(CURRENT_STORE)}{row?.payments?.[0]?.amount ? FloatValues(row?.payments?.[0]?.amount) : row?.orderTotal}</td>
                                        </tr>
                                        <tr className='subtotal-td'>
                                            <td>Payment method</td>
                                            <td></td>
                                            <td>Credit Card</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </React.Fragment>
                        ))}
                        {_.map(autoshiporder, (row) => (
                            <div className='auto-order' key={row?.orderId + '_autoship'}>
                                <h3><strong style={{ fontSize: "40px" }}>Autoship Order Details</strong></h3>
                                <div className="thanks-order">
                                    <div className='order-number'>
                                        <h3>Order Number:</h3>
                                        <div className='tanks-price'>{row?.recurringOrderId}</div>
                                    </div>
                                    <div className='order-number'>
                                        <h3>Date:</h3>
                                        <div className='tanks-price'>{row?.startDate?.split('T')[0]}</div>
                                    </div>

                                    <div className='order-number'>
                                        <h3>Total:</h3>
                                        <div className='tanks-price'>{getCurrencySign(CURRENT_STORE)}{FloatValues(row?.total)}</div>
                                    </div>
                                    <div className='order-number'>
                                        <h3>Payment Method:</h3>
                                        <div className='tanks-price'>Credit Card</div>
                                    </div>
                                </div>
                                <div className='table-data'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>PRODUCT</th>
                                                <th>QTY.</th>
                                                <th>TOTAL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {row?.details?.map((data, index) => {
                                                return (
                                                    <tr key={index + 1}>
                                                        <td>{data?.title}</td>
                                                        <td>{data?.quantity}</td>
                                                        <td>{getCurrencySign(CURRENT_STORE)}{FloatValues(data?.priceEach)}</td>
                                                    </tr>
                                                )
                                            })}

                                            <tr className='empty-td'>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr className='empty-td'>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>

                                            <tr className='subtotal-td'>
                                                <td><strong>Subtotal</strong></td>
                                                <td></td>
                                                <td>{getCurrencySign(CURRENT_STORE)}{FloatValues(row?.subTotal)}</td>
                                            </tr>

                                            <tr className='subtotal-td'>
                                                <td>Tax</td>
                                                <td></td>
                                                <td>{getCurrencySign(CURRENT_STORE)}{FloatValues(row?.totalTax)}</td>
                                            </tr>

                                            <tr className='subtotal-td'>
                                                <td>Shipping</td>
                                                <td></td>
                                                <td>{getCurrencySign(CURRENT_STORE)}{FloatValues(row?.total - row?.subTotal - row?.totalTax)}</td>
                                            </tr>

                                            <tr className='subtotal-td'>
                                                <td>Total</td>
                                                <td></td>
                                                <td>{getCurrencySign(CURRENT_STORE)}{FloatValues(row?.total)}</td>
                                            </tr>

                                            <tr className='subtotal-td'>
                                                <td>Payment method</td>
                                                <td></td>
                                                <td>Credit Card</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))}
                    </div>
                    {isDataLoaded && (
                        <div className='text-center md:mt-[30px] mt-[20px]'>
                            <ButtonComponent onClick={handlePrint} classes='min-w-[150px] print-button !mb-0'>
                                Print
                            </ButtonComponent>
                        </div>
                    )}
                </div>
            </ div>

        </>
    )
}

export default MainThankYou;