import _ from 'lodash';
import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import './App.css'
import LayoutComponent from './Layout'
import { ErrorBoundary } from "react-error-boundary";
import { ProductsFetchingError } from './Common/Components/ErrorBounderyCompnent';
import { useSelector } from 'react-redux';
import { getCustomerRefferal, getUserCredentialApi, _getCountryCodeApi } from './services/Redux/Reducer/CustomerSlice';
import withRouter from './services/HOC/withRouterHOC';
import { SignupRoutes, storeAssigned, _getEnrollerId, _getSSOKeyData, _getToken, _logoutFunction } from './services/Methods/normalMethods';
import { createSelector } from 'reselect';
import useScript from './services/Methods/UseScript.hook';
import GlobalStyle from '../styles/GlobalStyle';
import localStorageCall from './services/Methods/localstorage.hook';
import { _storeCustomerTypes } from './services/Methods/commonPayload';
import ScrollToTopOnPathChange from './Common/Components/ScrollToTopOnPathChange';
import withMiniCartHOC from './services/HOC/withMinCartHOC';
import LoaderComponent from './Inputs/LoaderComponent';
import * as Sentry from "@sentry/react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import useIpDetaction from './useIpDetaction';
import { getAutoShipOrders } from './services/Redux/Reducer/OrderSlice';
import countryListAllIsoData from './Common/Components/AllStatesCodeArray';

Sentry.init({
  dsn: "https://510c78089d4228cf9044703530460caf@o4506423182622720.ingest.sentry.io/4506500356505600",
  maxBreadcrumbs: 600,
  debug: false,
  environment: 'development',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [import.meta.env.VITE_APP_SITE_URL, /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [import.meta.env.VITE_MIDDLE_URL],
    })
  ],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeBreadcrumb: (breadcrumb, hint) => {

    if (breadcrumb.category === 'xhr') {
      const data = {
        ...breadcrumb.data,
        requestBody: hint.xhr.__sentry_xhr_v3__.body,
        response: hint.xhr.response,
        responseUrl: hint.xhr.responseURL
      }
      return { ...breadcrumb, data: data }
    }
    return breadcrumb
  },
  // Performance Monitoring
});


const STORES = ["us", "ca", 'au', 'nz', 'mx', 'ph', 'ng', 'cd', 'cm', 'ke', 'mu', 'gb'];

function App(props) {

  const { dispatch, searchParams, params, ROUTING_TEXT, location } = props;
  const navigate = useNavigate();
  const isUserLogin = _getToken();

  const countryCodes2 = createSelector(
    state => state.CustomerSlice,
    state => state?.LoginSlice?.alluserData,
    state => state?.PaymentSlice?.paymentScript,
    ({ countryData, isLoading }, userData, paymentScript) => ({
      isLoading,
      countryCodes: _.map(countryData, 'countryCode'),
      countyObjects: _.map(countryData, (row) => ({ code: row?.countryCode, name: row?.countryName })),
      userData: userData, paymentScript
    })
  );

  const [selectedStore, setSelectedStore] = useState({ defaultCode: 'us', newCode: 'us' });
  const [loader, setLoader] = useState(false);
  const [autoshipOrders, setAutoshipOrders] = useState([]);

  const { isLoading, countryCodes, countyObjects, paymentScript, userData } = useSelector((state) => countryCodes2(state));
  useScript(paymentScript);

  useEffect(() => { dispatch(_getCountryCodeApi()); }, []);

  useEffect(() => {
    if ((location?.pathname === '/' || location.pathname?.includes('vercel.app')) && !searchParams.get('ssokey')) {
      window?.location.assign(`/${storeAssigned()}`);
    }
    if (location?.pathname === '/energy') {
      window?.location.assign(`/api/Files/documents/EnergyProductOverview.pdf`);
    }
    if (location?.pathname === '/gsh') {
      window?.location.assign(`/api/Files/documents/GSHProductOverview.pdf`);
    }
    if (location?.pathname === '/ENERGY') {
      window?.location.assign(`/api/Files/documents/EnergyProductOverview.pdf`);
    }
    if (location?.pathname === '/GSH') {
      window?.location.assign(`/api/Files/documents/GSHProductOverview.pdf`);
    }
    if (location?.pathname === '/KTMist') {
      window?.location.assign(`/api/Files/documents/KT_Mist_Product_Sheet.pdf`);
    }
    if (location?.pathname === '/ktmist') {
      window?.location.assign(`/api/Files/documents/KT_Mist_Product_Sheet.pdf`);
    }
    const DEFUALT_STORE = localStorageCall()?.getSingleItem('store');
    if (!DEFUALT_STORE) {
      if (STORES?.includes(params?.store)) {
        localStorageCall().setItem('store', params?.store)
      }
    } else {
      if (DEFUALT_STORE !== params?.store) {
        if (STORES?.includes(params?.store)) {
          localStorageCall().setItem('store', params?.store);
          setSelectedStore({ defaultCode: params?.store, newCode: params?.store });
        } else {
          setSelectedStore({ defaultCode: DEFUALT_STORE, newCode: DEFUALT_STORE });
        }
      } else {
        setSelectedStore({ defaultCode: DEFUALT_STORE, newCode: DEFUALT_STORE });
      }
    }

  }, [location?.pathname]);

  const { success } = useIpDetaction(STORES, params?.store, navigate);

  useEffect(() => {
    if (location?.pathname !== "/KTMist") {
      if (!((_.map(countryListAllIsoData, (r) => _.lowerCase(r?.code)))?.includes(params?.store))) {
        success((currentIpCode) => {
          if (currentIpCode) {
            localStorageCall().setItem('store', currentIpCode);
          }

          if (searchParams.get('ssokey')) {
            const REDIRECT_PAGE = searchParams.get('redirectTo') ? searchParams.get('redirectTo') : "";
            dispatch(getUserCredentialApi(searchParams.get('ssokey'), navigate, REDIRECT_PAGE));
          } else {
            if (location?.pathname !== '/') {
              dispatch(getCustomerRefferal((data) => {
                if (data?.length > 0) {
                  setLoader(true);
                  const refferalData = JSON.stringify({ "id": data[0]?.customerId, "name": data[0]?.publicProfile?.fullName, "webalies": data[0]?.webAlias });
                  if (isUserLogin && refferalData) {
                    _logoutFunction('notRedirect');
                    localStorageCall().setItem('refferal_link', refferalData);
                  } else {
                    localStorageCall().setItem('refferal_link', refferalData);
                  }
                  if (searchParams?.get('pid') && searchParams?.get('placement') && searchParams?.get('tree')) {
                    if (isUserLogin) {
                      _logoutFunction('notRedirect');
                    }
                    const TreeMoveData = {
                      pid: searchParams.get('pid'),
                      placementId: searchParams.get('placement'),
                      treeType: searchParams.get('tree'),
                      placementType: searchParams.get('placementType')
                    }
                    localStorageCall().setItem('TreeMoveData', JSON.stringify(TreeMoveData));
                    const URL = (searchParams.get('role') === 'preferred') ? 'preferred_customer' : 'business_owner'
                    window?.location.assign(`/${storeAssigned()}/${URL}`);
                  }
                  else {
                    const REDIRECT_PAGE = searchParams.get('redirectTo') ? searchParams.get('redirectTo') : "";
                    if (REDIRECT_PAGE) {
                      navigate(REDIRECT_PAGE ? `/${storeAssigned()}${REDIRECT_PAGE}` : `/${storeAssigned()}`);
                    } else {
                      window?.location.assign(`/${storeAssigned()}`)
                    }
                    setLoader(false);
                  }
                } else {
                  window?.location.assign(`/${storeAssigned()}`)
                }
              }, params?.store));
            }

          }
        })
      }
    }
  }, [params, searchParams.get('ssokey')]);

  if (userData?.emailAddress) {
    Sentry.setUser({ id: userData?.customerId, email: userData?.emailAddress });
    Sentry.setTag("userID", userData?.customerId);
  }

  useEffect(() => {
    if (isUserLogin && userData === null) {
      _storeCustomerTypes(dispatch, isUserLogin, searchParams);
      dispatch(getAutoShipOrders(null, isUserLogin, (res) => {
        setAutoshipOrders(res?.data)
      }))
    } else {
      localStorageCall().removeItem('customerTypeDescr');
    }
  }, [isUserLogin]);


  return (
    <ErrorBoundary
      FallbackComponent={ProductsFetchingError}
      onError={() => console.log("Error happened!")}
    >
      <GlobalStyle />
      <ScrollToTopOnPathChange location={location?.pathname} />
      {/* <button type='button' onClick={() => { throw new Error('hhhhhhhhhhhhhhhhhhhhhhhh') }}>ttttttttttttttttttttt</button> */}
      {(isLoading || loader) && <LoaderComponent />}
      {SignupRoutes(location)
        ?
        <LayoutComponent {...{ ...props, userData, isUserLogin, countryCodes, countyObjects, selectedStore, setSelectedStore }}>
          <Outlet context={{ ...props, autoshipOrders, userData, isUserLogin, countryCodes, countyObjects }} />
        </LayoutComponent>
        :
        <Outlet context={{ ...props, autoshipOrders, userData, isUserLogin, countryCodes, countyObjects, selectedStore, setSelectedStore }} />
      }
    </ErrorBoundary>
  )
}

export default withRouter(withMiniCartHOC(App, 'normal'));
