import styled from "styled-components";

export const ViewCartStyle = styled.div`
  .view-cart {
        padding: 50px 0 ;
        .container {
            margin: 0 auto;
        } 
        .view-cart-part {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 4px 40px #0000001A;
            border-radius: 10px;
            padding: 40px 80px;
            @media(max-width:991px){
                /* padding: 210px 0px 98px; */
                padding: 50px 0 0;

            }
            h2{
                @media(max-width:991px){
                text-align: center;
            }
        }
        }
  }
  .table-data {
    color: #707070;
    font-size: 24px;
    text-align: left;
    margin: 50px 0;

    @media only screen and (max-width: 991px) {
        margin: 30px 0;
    }
}
.table-data table {
    border-collapse: separate;
    width: 100%;

}
.delete-product {
    display: flex;
    justify-content: center;
    padding-bottom: 14px;
}
.view-cart-part table th {
    font-size: 18px;
    font-weight: 500;
    /* font-family: "Qartella heavy"; */
    line-height: 28px;
    color: #707070;
    padding: 0 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ddd;
    @media only screen and (max-width: 1199px){
        font-size: 16px;
        line-height: 1.5;
    }
    @media(max-width:991px)  {
        display: none;
    }
}
.coupon button {
    background: #0071BA;
    border-radius: 28px;
    width: 88px;
    color: #fff; 
}
.product-image img {
    max-width: 103px;
    height: 180px;
    width: 100%;
    /* object-fit: cover; */
    object-fit: contain;
    margin-right: 37px;

    @media(max-width:1439px){
        margin-right: 21px;
    }

    @media(max-width:1199px){
        top: 25px;
        margin-right: 15px;
        max-width: 75px;
        height: 90px;
    }

    @media only screen and (max-width: 991px) {
        margin-right: 0;
        /* margin-bottom: 10px; */
    }
 
}

.view-cart-part table {
    border-collapse: collapse;

    @media only screen and (max-width: 991px) {
        display: block;
        padding: 0 10px;

        tbody {
            display: block;

            tr {
                display: block;

                &:not(:last-child) {
                    margin-bottom: 50px;
                }
            }
        }
    }

}
.product-image {
    /* padding: 60px 0 0; */
    padding: 10px 0;

    @media only screen and (max-width: 991px) {
        padding: 0;
    }
}
.coupon input{
    width: 409px;
    height: 60px;
    border-radius:5px;

}
input#quantity {
    max-width: 36px;
    width: 100%;
    border: none;
    height: 40px;
    background: transparent !important;
    color: #707070;
    font-size: 20px;
}
.view-cart-part .input-group button {
    background: transparent;
    border: none;
    color: #000;
}
.price-calulate{
     text-align: right;
}
.coupon button {
    background: rgb(42, 83, 63);
    border-radius: 28px;
    width: 88px;
    color: rgb(255, 255, 255);
    height: 40px;
    box-shadow: none;
    border: none;
    margin: 0 0 0 20px;
    font-size: 18px;
    font-weight: 500;
}
.view-cart-part .input-group {
    border: 1px solid #39332E;
    max-width: 112px;
    border-radius: 28px;
    height: 40px;
    align-items: center;
    @media (max-width:1199px) {
        flex-wrap: nowrap;
    }
}
.view-cart-part table td {
    border-bottom: 1px solid #55555566;
    /* vertical-align: bottom; */
    /* padding-bottom: 99px; */
    /* position: relative;
    bottom: 32px; */
    padding: 0 5px;

    @media (max-width:991px) {
       display:flex;
       width: 100%;
       justify-content: center;
       bottom:0;
       padding: 20px 0;
    /* padding-bottom:45px; */
    }

    @media only screen and (max-width: 767px) {
        padding: 15px 0;
    }
}
/* .view-cart-part table tr:first-child .product-image {
    padding: 0px;
} */
.view-cart-part .input-group i {
    color: #707070;
    font-weight: 300;
}
.view-cart th:last-child {
    text-align: center;
}
.sub-total {
    color: #39332E;
    font-weight: 700;
    /* text-align: right; */
    font-size: 22px;
    letter-spacing: 0.3px;
    /* font-family: "Qartella Regular"; */

    @media only screen and (max-width: 1199px) {
        font-size: 20px;
    }
}
.one-time {
    border: 1px solid rgb(117, 117, 117);
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 240px;
    margin: auto;
}
.selected {
    background: rgb(112 112 112 / 51%);
}
.common_css {
    /* padding: 0 16px !important; */
    cursor: pointer;
    width: calc(100%/2);

    @media(max-width:1199px){
        padding: 0 8px !important;
    }
}
.second-time.common_css {
    border-left: 1px solid #707070;
    border-right: 1px solid #707070;
}
/* .first-time.common_css p {
    padding-top: 31px;
} */
.price-calulate {
    padding: 40px 0 30px;
}
/* .second-time {
    
} */
.view-cart .product-image span {
    /* max-width: 240px; */
    /* width: 100%; */
    width: calc(100% - 140px);
    /* word-wrap: break-word; */

    @media (max-width: 1199px) {
        width: calc(100% - 90px);
    }
    @media(max-width:991px){
        max-width: 100%;
        width: 100%;
        font-size: 20px;
        text-align: center;
        flex: unset;
    }
}
.view-cart .price h6 {
    padding: 0;
    /* font-family:"Qartella heavy"; */
    font-size:13px;
}
.most-prices {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    max-width: 240px;
    margin: 0 auto;
}


.price-bar h6 {
    margin-bottom: 0;
}

.coupon input {
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    padding: 0 30px;
    color: #39332E;
    font-size: 20px;
    /* font-family: "Qartella Regular"; */
    font-weight: 500;
}
.price-calulate div {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 25px;
    color: #39332E;
}
.coupon {
    margin: 50px;
}
.checkout-btn button {
    border-radius: 28px;
    border: none;
    color: #fff;
    max-width: 292px;
    width: 100%;
    height: 54px;
    font-size: 24px;
    /* font-family: "Qartella heavy"; */
}
.coupon button:hover {
    background: transparent;
    /* border: 2px solid #0071BA; */
    color: #0071BA;
}
.checkout-btn button:hover {
    background: transparent;
    border: 2px solid #0071BA;
    color: #0071BA;
}
.view-cart-part th:nth-child(2) {
    text-align: center;
}
span.every {
    font-size: 14px;
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    display: block;
    padding: 10px 0 0;
    /* font-family: "Qartella Regular"; */
}
.first-time {
    padding: 30px  0 0;
}
.view-cart-part table tbody tr .product-image {
    /* vertical-align: bottom; */
    display: flex;
    align-items: center;
    width: 100% !important;

}
/* .view-cart-part table tr:first-child .product-image {
    padding: 0;
} */

 .price-bar {
    padding: 4px 0px;
    line-height: normal;
    /* position: relative;
    right: -53px; */
    max-width: 133px;
    margin-left: auto;
}




    @media(max-width:1199px){
    /* max-width: 161px; */
    right: 0;
    }

@media (max-width:1500px){
.sub-total {
    font-size: 24px;
}
.view-cart-part table th {
    font-size: 20px;
}
.view-cart .product-image span {
    max-width: 220px;
    width: 100%;
}
}
@media (max-width:1439px){
.product-image span {
    font-size: 18px;
}
.view-cart .view-cart-part {
    padding: 40px;
}
}



@media (max-width:1280px){
.view-cart-part table th {
    font-size: 18px;
}
}
@media (max-width:1199px){
    .product-image span {
        font-size: 16px;
    }
}
@media (max-width:991px){
.view-cart-part table th {
    font-size: 18px;
}
.price {
    max-width: 400px;
    width: 100%;
}
 .most-prices {
    display: block;
    justify-content: left;
    max-width: 100% ;
}
.common_css {
    padding: 0px 34px !important;
}
.price-bar {
    max-width: 100%;
    right: 0;
    float: right;
}
.view-cart-part table td {
    bottom: 0px;
    /* padding-bottom: 30px; */
    border-bottom: none ;

}
.view-cart .product-image span {
    line-height: normal;
    flex: 0 0 100%;
    display: block;
    max-width: 80%;
    text-align: left;
}
.view-cart-part table tbody tr .product-image {
    /* align-items: center;
    vertical-align: top; */
    flex-direction: column;
}
/* .view-cart-part table td:first-child {
    padding-bottom: 70px;
} */
.price-calulate div {
    font-size: 24px;
    margin: 0px 0px 18px;
    text-align: center;
}
 .price-calulate {
    padding: 20px;
    background: #ddd;
}
.price-calulate div {
    font-size: 17px;
}
.table-data {
    margin: 10px 0 0;
}
}

@media(max-width:568px){
   .view-cart .view-cart-part {
    padding: 10px;
}
/* .product-image {
    flex-wrap: wrap;
    justify-content: center;
} */
/* .view-cart-part table td:first-child {
    padding-bottom: 23px;
} */
.one-time {
    justify-content: left;
}
 .view-cart {
    padding: 120px 0px;
}
 .checkout-btn button {
    max-width: 159px;
    height: 46px;
    font-size: 18px;
}
.product-image img {
    margin-right: 0;
}
.view-cart .product-image span {
    font-size: 16px;
    /* margin: 54px 0px 0px; */
    max-width: 100%;
    /* word-break: break-all; */
}
}
@media(max-width:459px){
 .common_css {
    padding: 0px 25px !important;
}
}

@media(max-width:405px){
.common_css {
    padding: 0px 17px !important;
}
}
  `;