import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import ButtonComponent from '../../Inputs/ButtonComponent';
import _ from 'lodash';
import { storeAssigned } from '../../services/Methods/normalMethods';
import withRouter from '../../services/HOC/withRouterHOC';
import { loginDefaultSchema, loginIntialValue } from '../../services/Methods/validationSchema';
import { LoginApi } from '../../services/Redux/Reducer/LoginSlice';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import LoaderComponent from '../../Inputs/LoaderComponent';

const Login = ({
  navigate, dispatch, searchParams, isUserLogin, ROUTING_TEXT, LOGIN_TEXT
}) => {

  const memorizeSelector = createSelector(
    (state) => state?.LoginSlice,
    (loginState) => loginState
  )
  const { emailerror, loading, ...loginState } = useSelector((state) => memorizeSelector(state));

  useEffect(() => {
    if (loginState?.data) {
      if (searchParams.get('redirectTo')) {
        navigate(`/${storeAssigned()}${searchParams.get('redirectTo')}`);
      } else {
        navigate('/');
      }
    } else {
      if (isUserLogin) {
        navigate('/');
      }
    }
  }, [loginState?.data]);

  useEffect(() => {
    if (emailerror) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [emailerror])

  const formik = useFormik({
    initialValues: loginIntialValue,
    validationSchema: loginDefaultSchema,
    onSubmit: (values) => dispatch(LoginApi(values))
  });

  return (
    <section className="min-h-[calc(100vh-256px)] py-[50px] bg-[#f7f7f7]">
      {loading && <LoaderComponent />}
      <div className="container xl:max-w-[1260px] mx-auto px-[15px]">
        <div className="max-w-[380px] mx-auto text-center">
          {emailerror && <p className='m-[10px] text-[#f00]'>{emailerror}</p>}
          <form
            className="p-9 rounded-[7px] bg-white shadow-[0px_0px_10px_0px_rgba(0,0,0,0.1)] text-left"
            onSubmit={formik.handleSubmit}
          >
            {_.map([
              { label: LOGIN_TEXT?.email_text, name: 'email' },
              { label: LOGIN_TEXT?.password, name: 'password' }
            ], ({ label, name }) => (
              <div className="mb-4" key={name}>
                <label className="text-[14px] leading-[1.5] font-normal mb-[6px] block w-full text-heading-color pt-[1px]" htmlFor="email">
                  {label}
                </label>
                <input
                  type={(name === "email") ? "text" : name}
                  name={name}
                  placeholder={(name === "email") ? "example@gmail.com" : '******'}
                  className={`px-4 py-[10px] rounded-[5px] border-[1px] border-[#dae1e7] text-[15px] leading-[1.5] font-normal inline-block w-full ${formik.touched.email && formik.errors.email
                    ? 'border-red-500'
                    : ''
                    }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[name]}
                />
                {formik.touched[name] && formik.errors[name] ? (<div className="text-red-500 text-[14px] mt-1">{formik.errors[name]}</div>) : null}
              </div>
            ))}

            <div className='flex space-x-2'>
              <ButtonComponent
                type="submit"
                classes="w-full max-w-[140px] !mb-0 bg-button-color text-white transition-all duration-300 ease-in-out hover:bg-button-hover-color"
                disabled={formik.isSubmitting}
              >
                {LOGIN_TEXT?.sign_in}
              </ButtonComponent>
              <ButtonComponent
                classes="w-full max-w-[140px] !mb-0 bg-button-color text-white transition-all duration-300 ease-in-out hover:bg-button-hover-color"
                onClick={() => navigate(`/${storeAssigned()}/${ROUTING_TEXT?.signup_routingName}`)}
              >
                {LOGIN_TEXT?.sign_up}
              </ButtonComponent>
            </div>
          </form>
          <a
            href={`/${storeAssigned()}/${ROUTING_TEXT?.reset_password_routingName}`}
            className="text-base font-normal mt-4 inline-block text-button-color transition-all duration-300 ease-in-out hover:text-button-hover-color"
          >
            {LOGIN_TEXT?.forgetPassword_text}
          </a>
        </div>
      </div>
    </section>
  );
};

export default withRouter(Login);