import React, { useState } from 'react';
import ExploreDevider from '../assets/explore-devider.jpg'
import Molecules from '../assets/molecules.png'
import Bioblend from '../assets/bioblend.png'
import BioblendNew from '../assets/bioblendnew.jpg'
import Bottle from '../assets/bottle.png'
import Capsules from '../assets/capsules.png'
import EnergyProducts from '../assets/products.png'
import ExploreHome from '../assets/explore-home.jpg'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './health.css';
import _ from 'lodash';
import classNames from 'classnames';
import Products from '../assets/product-ingr.png';
import AllProductAndCheckoutText from '../../../TextConfigs/AllProductAndCheckout.text';
import { Link, useNavigate } from 'react-router-dom';
import { storeAssigned } from '../../../services/Methods/normalMethods';
import ROUTING_TEXT from '../../../TextConfigs/RoutingAndSmallContent.text';
import DialogComponent from '../../../Inputs/DailogComponent';
import ProvizionEnergy from "../assets/supplement-facts-provision-energy.png"
import ProvizionGSH from '../assets/supplement-facts.png'
import Ktmist from '../assets/ktmist.jpeg'

const Gsh = ({ HEALTH_TEXT }) => {
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState({
        state: false,
        section: 'GSH'
    })
    const tabContentData = [
        {
            title: 'Superoxide Dismutase',
            description:
                'The GSH BioBlend works synergistically to help neutralize free radicals and may assist to eliminate harmful toxins thereby reducing oxidative stress in the body and promoting vibrant health, which may in turn promote longevity.',
        },
        {
            title: 'Bioavailable L-Glutathione (GSH)',
            description:
                'The GSH BioBlend works synergistically to help neutralize free radicals and may assist to eliminate harmful toxins thereby reducing oxidative stress in the body and promoting vibrant health, which may in turn promote longevity.              ',
        },

        {
            title: 'S-Acetyl-L-Glutathione',
            description:
                'The GSH BioBlend works synergistically to help neutralize free radicals and may assist to eliminate harmful toxins thereby reducing oxidative stress in the body and promoting vibrant health, which may in turn promote longevity.              ',
        },
        {
            title: 'Organic Aloe Vera Powder (inner Leaf), to provide Acemannan',
            description:
                'The GSH BioBlend works synergistically to help neutralize free radicals and may assist to eliminate harmful toxins thereby reducing oxidative stress in the body and promoting vibrant health, which may in turn promote longevity.          ',
        },
        {
            title: 'Superoxide Dismutase',
            description:
                'The GSH BioBlend works synergistically to help neutralize free radicals and may assist to eliminate harmful toxins thereby reducing oxidative stress in the body and promoting vibrant health, which may in turn promote longevity.              ',
        },
    ];
    const tabIcons = [
        'fa-dna',
        'fa-atom',
        'fa-capsules',
        'fa-spa',
        'fa-hand-holding-heart',
    ];

    const { CGMP_compliant, NSF_certified, FDA_registered } = AllProductAndCheckoutText;
    return (
        <>
            <section className="bg-cover bg-no-repeat py-[150px] relative" style={{ backgroundImage: `url(${ExploreDevider})` }}>
                <div className="absolute top-0 left-0 w-full h-full z-10 opacity-40 bg-[#E6E6E6]"></div>
                <div className="container mx-auto max-w-[1260px] px-[15px] relative z-20">
                    <div className="text-center">
                        <h2 className='lg:text-[48px] text-[43px] text-heading-color leading-[1.46] font-extrabold'>
                            <span className='font-light block'>{HEALTH_TEXT?.how_many_product_heading}</span>
                            {HEALTH_TEXT?.with_provizion}
                        </h2>
                    </div>
                </div>
            </section>

            <section className="lg:pt-[160px] lg:pb-[80px] py-[40px]">
                <div className="container mx-auto max-w-[1260px] px-[15px] relative z-20">
                    <div className="flex flex-wrap -mx-[15px]">
                        <div className="md:max-w-[735px] max-w-full px-[15px] text-center mx-auto pb-6 mb-9">
                            <h2 className='text-[40px] leading-[1.2] mb-3 font-extrabold text-heading-color'>{HEALTH_TEXT?.why_use_gsh}</h2>
                            <p className='text-lg leading-[1.5] mb-4 text-dark-content'>{HEALTH_TEXT?.gsh_bioblend}</p>
                            <p className='text-lg leading-[1.5] mb-4 text-dark-content'>{HEALTH_TEXT?.may_support_natural}</p>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-[15px]">
                        <div className="xl:w-2/6 md:w-1/2 w-full pl-[15px] md:pr-[40px] pr-[15px]">
                            {_.map([
                                { text: HEALTH_TEXT?.defense_system_text, icon: "fa-shield-halved" },
                                { text: HEALTH_TEXT?.mental_performace_text, icon: "fa-brain" },
                                { text: HEALTH_TEXT?.protection_harmful_effect, icon: "fa-user-shield" },
                                { text: HEALTH_TEXT?.optimisation_energy_level, icon: "fa-battery-three-quarters" },
                            ], (row, index) => (
                                <div className="group flex items-center justify-between md:mb-[65px] mb-[35px]" key={index + '_upper'}>
                                    <h5 className='w-[calc(100%-77px)] text-xl leading-[1.2] font-semibold text-heading-color transition-all ease-linear duration-300 group-hover:text-button-color md:order-1 order-2'>{row?.text}</h5>
                                    <span className='block h-[60px] w-[60px] text-[25px] leading-[60px] text-heading-color text-center md:ml-[17px] ml-[0px] md:mr-[0px] mr-[17px] border-2 border-dashed border-heading-color rounded-[100%] transition-all ease-linear duration-300 group-hover:text-button-color group-hover:border-button-color md:order-2 order-1'>
                                        <i className={`fa-solid ${row?.icon}`}></i>
                                    </span>
                                </div>
                            ))}
                        </div>
                        <div className="w-2/6 xl:block hidden px-[15px]">
                            <figure className='my-5'>
                                <img src={Molecules} alt="Image" className='w-full h-auto object-contain' />
                            </figure>
                        </div>
                        <div className="xl:w-2/6 md:w-1/2 w-full pr-[15px] md:pl-[40px] pl-[15px]">
                            {_.map([
                                { text: HEALTH_TEXT?.detoxification_text, icon: "fa-heart-pulse" },
                                { text: HEALTH_TEXT?.mental_performace_text, icon: "fa-mattress-pillow" },
                                { text: HEALTH_TEXT?.revitalization_youthful, icon: "fa-hand-dots" },
                                { text: HEALTH_TEXT?.athletic_performance, icon: "fa-person-running" },
                            ], (row, index) => (
                                <div className="group flex items-center justify-between  md:mb-[65px] mb-[35px]" key={index + '_lower'}>
                                    <span className='block h-[60px] w-[60px] text-[25px] leading-[60px] text-heading-color text-center mr-[17px] border-2 border-dashed border-heading-color rounded-[100%] transition-all ease-linear duration-300 group-hover:text-button-color group-hover:border-button-color'>
                                        <i className={`fa-solid ${row?.icon}`}></i>
                                    </span>
                                    <h5 className='w-[calc(100%-77px)] text-xl leading-[1.2] font-semibold text-heading-color transition-all ease-linear duration-300 group-hover:text-button-color'>{row?.text}</h5>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="max-w-[730px] mx-auto text-center">
                        <figure className="mt-[40px] mb-[20px] max-w-[500px] mx-auto">
                            <img src={Products} alt="Image" className='w-full h-auto object-contain' />
                        </figure>
                        <p className='text-base text-dark-content'>
                            {_.map([
                                { text: HEALTH_TEXT?.cgmp_complaint, link: CGMP_compliant?.link },
                                { text: HEALTH_TEXT?.nsf_certified, link: NSF_certified?.link },
                                { text: HEALTH_TEXT?.fda_registered, link: FDA_registered?.link }], (row, index) => (
                                    <React.Fragment key={index + '_manu'}>
                                        {HEALTH_TEXT?.manufacture_in} <a href={row?.link} target='_blank' className='text-button-color hover:text-button-hover-color transition-all ease-in-out duration-300'>{row?.text}</a>.&nbsp;
                                    </React.Fragment>
                                ))}
                            {HEALTH_TEXT?.manufacture_in} {HEALTH_TEXT?.kosher_and_halal_certified}
                        </p>
                    </div>
                </div>
            </section>

            <section className="py-[50px]">
                <div className="container mx-auto max-w-[1260px] px-[15px] relative z-20">
                    <div className="flex flex-wrap items-center -mx-[15px]">
                        <div className="lg:w-6/12 md:w-1/2 md:order-2">
                            <figure className="px-[15px] lg:w-10/12 w-full ml-auto">
                                <img src={BioblendNew} alt="Image" className="w-full h-auto object-contain" />
                            </figure>
                        </div>
                        <div className="lg:w-5/12 md:w-1/2">
                            <div className="px-[15px] md:py-0 py-6 lg:w-4/5 w-full ml-auto">
                                <h2 className='mx-auto text-heading-color text-[28px] leading-[1.2] text-center font-extrabold pb-2 mb-6'>{HEALTH_TEXT?.gsh_bioBlend_heading}</h2>
                                <p className='text-[14px] leading-[1.5] m-0 mb-4 pb-2 text-content-color'>{HEALTH_TEXT?.GSH_BioBlend_fusion_para}</p>
                                <div className='icon-tab-wrap'>
                                    <h5 className='text-[17px] font-semibold leading-[1.2] mb-3 pb-2 text-button-color'>Click ICONS for the 5 Core Sciences</h5>
                                    <Tabs>
                                        <TabList>
                                            {tabIcons.map((icon, index) => (
                                                <Tab key={index}>
                                                    <span>
                                                        <i className={`fa-solid ${icon}`}></i>
                                                    </span>
                                                </Tab>
                                            ))}
                                        </TabList>
                                        {_.map(HEALTH_TEXT?.gsh_bioBlend_inside_tabs, (row, index) => (
                                            <TabPanel key={index + "_tab_gsn"}>
                                                <div className="icon-tab-content">
                                                    <h6 className='text-[14px] leading-[1.5] font-normal text-button-color mb-6'>{row}</h6>
                                                </div>
                                            </TabPanel>
                                        ))}
                                    </Tabs>
                                </div>
                                <div className='text-[14px] leading-[1.5] m-0 mb-4 pb-2 text-content-color'>
                                    The GSH BioBlend works synergistically to help neutralize free radicals and may assist to eliminate harmful toxins thereby reducing oxidative stress in the body and promoting vibrant health, which may in turn promote longevity.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {_.map([
                { section: "GSH", dataArray: HEALTH_TEXT?.gsh_ingredient_array },
                { section: "ENERGY", dataArray: HEALTH_TEXT?.energy_ingredient_array },
                { section: "KT MIST", dataArray: HEALTH_TEXT?.ktmist_ingredient_array }
            ], ({ dataArray, section }) => (
                <section className="py-[80px] bg-[#fafcfc]" key={section}>
                    <div className="container mx-auto max-w-[1260px] px-[15px]">
                        <div className="flex flex-wrap -mx-[15px]">
                            <div className="w-full px-[15px] pb-6 mb-9">
                                <h2 className='text-[40px] leading-[1.2] font-extrabold mb-3 text-heading-color text-center'>{HEALTH_TEXT?.provizion_text} {section} {HEALTH_TEXT?.ingredients_text}</h2>
                            </div>
                        </div>
                        <Tabs>
                            <div className="flex flex-wrap justify-center -mx-[15px]">
                                <div className="md:w-6/12 w-full">
                                    <div className="md:w-5/6 w-full px-[15px] ml-auto mb-6">
                                        <h5 className='text-xl leading-[1.2] font-semibold mb-3 pb-2 text-button-color'>{HEALTH_TEXT?.click_on_ingredient}</h5>
                                        <div className="flex flex-wrap justify-between border-b-[5px] border-[#333740]">
                                            <h5 className='text-xl leading-[1.2] mb-3 font-extrabold uppercase'>{HEALTH_TEXT?.ingredients_text}</h5>
                                            {section !== 'KT MIST' && <h6 className='text-[17px] leading-[1.2] mb-3 font-extrabold uppercase'>{HEALTH_TEXT?.per_serving_text}</h6>}
                                        </div>
                                        <div className="indegredients-tab">
                                            <TabList>
                                                {_.map(dataArray, ({ heading, perServing }, index) => (
                                                    <Tab key={index + 1}><span>{heading}</span><span className='text-[13px]'>{perServing}</span></Tab>
                                                ))}
                                            </TabList>
                                        </div>
                                        {section !== 'KT MIST' && <button onClick={() => setOpenDialog({ state: true, section: section })} className='py-[14px] px-[48px] text-base rounded-sm uppercase bg-button-color text-white block w-full'>{HEALTH_TEXT?.click_view_suppliment}</button>}
                                        <Link className='text-[19px] font-normal text-white bg-[#DEAA17] px-[40px] py-[15px] border-[1px] border-white mt-6 max-w-[345px] mx-auto block transition-all ease-in-out duration-300 hover:border-[#c5c5c5]' to={section === 'GSH' ? '/api/Files/documents/GSHProductOverview.pdf' : section === 'KT MIST' ? '/api/Files/documents/KT_Mist_Product_Sheet_new.pdf' : '/api/Files/documents/EnergyProductOverview.pdf'} target='_blank'>{HEALTH_TEXT?.click_to_download_pdf(section)}</Link>
                                    </div>
                                </div>
                                <div className="md:w-5/12 w-full">
                                    <div className="md:w-4/5 w-full px-[15px] ml-auto">
                                        {/* <figure className="w-1/2 px-[15px] mx-auto"> */}
                                        <figure className={`w-full  m-auto mb-2  ${section === 'KT MIST' ? 'max-w-[450px] h-[auto]' : 'max-w-[320px] h-[298px]'}`}>
                                            <img src={section === 'GSH' ? Bottle : section === 'KT MIST' ? Ktmist : Capsules} alt="Image" className='w-full h-full object-contain' />
                                        </figure>
                                        {_.map(dataArray, ({ heading, subheading, perServing, para }, index) => (
                                            <TabPanel key={index + section}>
                                                <div className="mb-5">
                                                    <span className='block h-[60px] w-[60px] text-[25px] leading-[60px] text-heading-color text-center border-2 border-dashed border-heading-color rounded-[100%] transition-all ease-linear duration-300 group-hover:text-button-color group-hover:border-button-color'>
                                                        <i className="fa-solid fa-wheat-awn"></i>
                                                    </span>
                                                </div>
                                                <div className="mb-[30px] pl-[30px] relative before:content-[''] before:h-full before:w-[5px] before:bg-button-color before:absolute before:left-0 before:top-0">
                                                    <h4 className='text-2xl leading-[1.2] text-heading-color font-extrabold'>{heading} {subheading && '(' + subheading + ')'}</h4>
                                                    <p className='text-base font-light leading-[1.2] mt-3'>{perServing}</p>
                                                </div>
                                                <p className='text-base mb-4 text-dark-content'>{para}</p>
                                            </TabPanel>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </section>
            ))}
            <DialogComponent opend={openDialog.state} handleClose={() => setOpenDialog({ state: false, section: 'GSH' })} title="Supplement Facts" classFor={openDialog.section !== 'GSH' && "cstm-popup"}>
                <img src={openDialog.section === 'GSH' ? ProvizionGSH : ProvizionEnergy} />
            </DialogComponent>
            {_.map([
                {
                    id: 1, image: EnergyProducts, heading: HEALTH_TEXT?.get_provizion_now_text, para: HEALTH_TEXT?.get_provizion_now_para, buttonName: HEALTH_TEXT?.purchase_now_text,
                    link: `/${storeAssigned()}/${ROUTING_TEXT?.signup_routingName}`
                },
                {
                    id: 2, image: ExploreHome, heading: HEALTH_TEXT?.explore_business, para: HEALTH_TEXT?.gsn_exploreBusiness_text, buttonName: HEALTH_TEXT?.explore_business,
                    link: `/${storeAssigned()}/${ROUTING_TEXT?.explore_business_routingName}`
                }
            ], (row) => (
                <section className={classNames("py-[100px]", { 'bg-button-color': (row?.id === 1), 'bg-[#f3f2f2]': (row?.id === 2) })} key={'section_' + row?.id}>
                    <div className="container mx-auto max-w-[1260px] px-[15px]">
                        <div className={classNames("flex flex-wrap items-center -mx-[15px]", { 'md:flex-row-reverse': (row?.id === 1) })}>
                            <div className={classNames("px-[15px] md:w-1/2", { 'w-2/3 mx-auto': (row?.id === 1), 'w-full': (row?.id === 2) })}>
                                <figure className='text-center'>
                                    <img src={row?.image} alt="Image" className='w-full h-auto object-contain' />
                                </figure>
                            </div>
                            <div className="md:w-1/2  w-full md:text-left text-center px-[15px]">
                                <div className={classNames("mb-9 max-w-[30%] md:mt-0 mt-[60px] md:mx-0 mx-auto h-[3px]", { 'bg-white': (row?.id === 1), 'bg-heading-color': (row?.id === 2) })}></div>
                                <h2 className={classNames('lg:text-[48px] text-[43px] leading-[1] font-extrabold mb-4', { 'text-white': (row?.id === 1), 'text-heading-color': (row?.id === 2) })}>{row?.heading}</h2>
                                <p className={classNames('text-xl leading-[1.2] m-0 mb-3', { 'text-white': (row?.id === 1), 'text-heading-color': (row?.id === 2) })}>{row?.para}</p>
                                <button onClick={() => navigate(row?.link)} className="inline-block py-[19px] px-[45px] rounded-[30px] bg-[#DEAA17] text-[19px] text-white leading-[1.5] font-normal transition-all duration-300 ease-in-out mt-6 hover:text-black hover:bg-white">
                                    {row?.buttonName}<span className="ml-[7px]"><i className="fa-solid fa-arrow-right"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            ))}
        </>
    )
};
export default Gsh;