import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import AboutPage from "./pages/about";
import ExploreBusinessPage from "./pages/ExploreBusiness";
import ExploreHealthPage from "./pages/ExploreHealth";
import CreateSignUpPage from "./pages/CreateSignUp";
import HomePage from "./pages/home/Index";
import Login from "./pages/login/Login";
import ROUTING_TEXT from "./TextConfigs/RoutingAndSmallContent.text";
import RefundPolicy from "./pages/Documents/RefundPolicy";
import PrivacyPolicy from "./pages/Documents/PrivacyPolicy";
import ShippingDisclosure from "./pages/Documents/ShippingDisclosure";
import IncomeDisclosure from "./pages/Documents/IncomeDisclosure";
import TermsAndConditions from './pages/Documents/TermsAndConditions';
import RetailSignUp from './pages/retailSignup'
import CustomerSignUpPage from './pages/CustomerSignup'
import IBOSignup from "./pages/IBOSignup";
import PreferredCustomerSignup from "./pages/preferredCustomerSignup";
import StorePage from "./pages/StorePage";
import Shop from "./pages/Shop";
import ViewCartPage from "./pages/viewCart";
import ThankYouPage from './pages/Thankyou';
import CheckoutPage from "./pages/CheckoutPage";
import AutoshipEditPage from './pages/AutoshipEdit'
import ErrorElement from "./ErrorElement";
import SingleProductPage from "./pages/singleProductPage";
// import NewPassWord from "./pages/login/NewPassword";
import ComingSoon from "./pages/ComingSoon";
import ForgotPasswordPage from "./pages/forgotPassword";
import * as Sentry from '@sentry/react';
import KTMist from "./pages/KTMist";
import IncentivePage from "./pages/you-share-they-share";

const {
    landing_page_routingName, about_routingName, explore_business_routingName, explore_health_routingName, signup_routingName,
    privacy_routingName, refund_routingName, shipping_info_routingName, login_routingName, forgot_password_routingName, reset_password_routingName, home_routingName,
    terms_routingName, income_routingName, checkout_routingName, userinfo_routingName, retailSignup_routingName, customerSignup_routingName,
    preferredSignup_routingName, IBOSignup_routingName, Shop_routingName, viewCart_routingName, thankyou_routingName, autoship_routingName, ktm_routingName,
    incentive_routingName
} = ROUTING_TEXT;

const routeElement = import.meta.env.VITE_IS_COMING_SOON === 'true' ? <ComingSoon /> : <HomePage />;

const sentryBrowserRouting = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const ROUTING = sentryBrowserRouting([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorElement />,
        children: [
            { path: ktm_routingName, element: <KTMist /> },
            {
                path: `:store`,
                element: <StorePage />,
                children: [
                    { path: landing_page_routingName, element: routeElement },
                    { path: login_routingName, element: <Login /> },
                    { path: reset_password_routingName, element: <ForgotPasswordPage /> },
                    // { path: reset_password_routingName, element: <NewPassWord /> },
                    { path: home_routingName, element: <HomePage /> },
                    { path: terms_routingName, element: <TermsAndConditions /> },
                    { path: income_routingName, element: <IncomeDisclosure /> },
                    { path: shipping_info_routingName, element: <ShippingDisclosure /> },
                    { path: refund_routingName, element: <RefundPolicy /> },
                    { path: privacy_routingName, element: <PrivacyPolicy /> },
                    { path: about_routingName, element: <AboutPage /> },
                    { path: explore_business_routingName, element: <ExploreBusinessPage /> },
                    { path: explore_health_routingName, element: <ExploreHealthPage /> },
                    { path: signup_routingName, element: <CreateSignUpPage /> },
                    { path: retailSignup_routingName, element: <RetailSignUp /> },
                    { path: customerSignup_routingName, element: <CustomerSignUpPage /> },
                    { path: preferredSignup_routingName, element: <PreferredCustomerSignup /> },
                    { path: IBOSignup_routingName, element: <IBOSignup /> },
                    { path: viewCart_routingName, element: <ViewCartPage /> },
                    { path: Shop_routingName, element: <Shop /> },
                    { path: `${Shop_routingName}/:id/:name`, element: <SingleProductPage /> },
                    { path: checkout_routingName, element: <CheckoutPage /> },
                    { path: thankyou_routingName, element: <ThankYouPage /> },
                    { path: autoship_routingName, element: <AutoshipEditPage /> },
                    { path: incentive_routingName, element: <IncentivePage /> },
                    // { path: ktm_routingName, element: <KTMist /> }
                    // {
                    //     path: checkout_routingName, element: <HomePage />,
                    //     children: [
                    //         { path: userinfo_routingName, element: <HomePage /> },
                    //     ]
                    // }
                ],
            },

        ]
    }
]);
const ROUTERS = () => <RouterProvider router={ROUTING} />
export default ROUTERS;