import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DialogActions, FormControl, FormControlLabel, IconButton, Radio, RadioGroup } from '@mui/material';
import DialogComponent from '../../../Inputs/DailogComponent';
import { Form, Formik } from 'formik';
import AddressSection from './AddressChangeForm';
import { customerUpdateApi } from '../../../services/Redux/Reducer/LoginSlice';
import withRouter from '../../../services/HOC/withRouterHOC';
import parse from 'html-react-parser';
import ButtonComponent from '../../../Inputs/ButtonComponent';
import { VALIDATION_SCHEMA } from '../../../services/Methods/validationSchema';
import { ButtonCssClass, storeAssigned } from '../../../services/Methods/normalMethods';
import CountryFlagDropdown from '../../../Layout/Header/CountryFlagDropdown';
import ReactCountryFlag from 'react-country-flag';
import localStorageCall from '../../../services/Methods/localstorage.hook';
import { useOutletContext } from 'react-router-dom';

const AddessOptionStyle = styled.div`
    &.mainAddress_option {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        width: 100%;

        .address_line {
            width: 100%;
            .MuiFormControl-root {
                width: 100%;
            }
            .addAddress_class {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid;
                margin-bottom: 10px;
                padding:0 4px;
                p {
                    margin-bottom: 4px;
                    font-weight: 600;
                    &:first-child {
                        color: #000;
                        font-size: 22px;
                    }
                    &:last-child {
                        font-weight: 600;
                        cursor: pointer;
                        color: #0171ba;
                    }
                }
            }
            .radio_section {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                /* gap: 10px; */
                margin-bottom: 4px;
                padding: 8px 13px 8px 0px;

                &>div {
                    margin-left: 42px;
                }

                .MuiRadio-root {
                    padding:9px !important;
                }

                @media only screen and (max-width: 767px) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                &.activeAddress{
                    background: #2a533f1a;
                    border-radius: 5px;
                }
                label {
                    margin: 0; 

                    @media only screen and (max-width: 767px) {
                        align-items: flex-start;
                    }

                    .MuiButtonBase-root {
                        @media only screen and (max-width: 767px) {
                            padding: 0 9px;
                        }   
                    }

                    p {
                        margin-bottom: 0;
                        strong {
                            font-size: 18px;
                        }
                    }
                }
                .editIcon {
                    color: #0171ba;
                    cursor: pointer;
                    font-weight: 600;
                    font-size: 14px;
                    &:hover {
                        border-bottom: 1px solid #0171ba;
                    }
                }
                .DelIcon {
                    color: #f00;
                    &:hover {
                        border-bottom: 1px solid #f00;
                    }
                }

                &>div {
                    @media only screen and (max-width: 767px) {
                        width: 100%;
                        margin-left: 40px;
                    }
                }
            }
        }
    }

`;

const AddressOptions = ({
    allUserData, _setUseDefaultAddressFunction, userFormData, setUserFormData, setDefaultValues, dispatch,
    sameAsBillingChecked, _paymentOptionSetBilling, checkoutError, section, ...props
}) => {

    const { countyObjects } = useOutletContext();
    const [addressObject, setAddressesObject] = useState({ oldData: {}, shippingAddress: {}, addresses: [] });
    const [openDialog, setOpenDialog] = useState(null);

    const [marketOpenDialog, setMarketOpenDialog] = useState(false);
    const [storeDropdownOpen, setStoreDropdownOpen] = useState(false);
    const [newMarketCode, setNewMarketCode] = useState(_.upperCase(storeAssigned()));

    function userDataPayload(data) {
        return ({
            "shipping_urname": allUserData?.firstName,
            "shipping_lastname": allUserData?.lastName,
            "shipping_email": allUserData?.emailAddress,
            "shipping_phone": allUserData?.phoneNumbers?.cellPhone,
            "shipping_delivery": '',
            "shipping_street": data?.address1,
            "shipping_street2": data?.address2,
            "shipping_city": data?.city,
            "shipping_zipcode": data?.postalCode,
            "shipping_country": data?.countryCode,
            "shipping_state": data?.regionProvState
        })
    }

    function _addressPayload(values) {
        return ({
            address1: values?.shipping_street || '',
            "address2": values?.shipping_street2 || "",
            "address3": "",
            city: values?.shipping_city || "",
            regionProvState: values?.shipping_state || "",
            postalCode: values?.shipping_zipcode || "",
            countryCode: values?.shipping_country || ""
        })
    }

    useEffect(() => {
        if (allUserData?.emailAddress) {
            const PICK_ADDRESSES = _.pick(allUserData, ['shippingAddress', 'billingAddress', 'mailingAddress', 'otherAddress1', 'otherAddress2']);

            const ADDRESSES = _.compact(_.map(_.values(PICK_ADDRESSES), (val, index) => {
                const SECTION = _.keys(PICK_ADDRESSES);
                if (SECTION[index] !== "shippingAddress") {
                    const { address1, city, postalCode } = PICK_ADDRESSES?.shippingAddress;
                    if (val?.address1 !== address1) {
                        return ({
                            id: index + 1,
                            firstName: allUserData?.firstName,
                            lastName: allUserData?.lastName,
                            section: SECTION[index], ...val
                        });
                    }
                } else {
                    return ({
                        id: index + 1,
                        firstName: allUserData?.firstName,
                        lastName: allUserData?.lastName,
                        section: SECTION[index], ...val
                    });
                }
            }))
            const FilterAddresAccStore = _.filter(ADDRESSES, (r) => r?.countryCode === _.upperCase(storeAssigned()));
            setAddressesObject({
                oldData: allUserData,
                shippingAddress: _.find(FilterAddresAccStore, (r) => r?.address1 !== ''),
                addresses: ADDRESSES
            });

            setUserFormData(userDataPayload(_.find(FilterAddresAccStore, (r) => r?.address1 !== '')));
            _setUseDefaultAddressFunction(null, null, userDataPayload(_.find(FilterAddresAccStore, (r) => r?.address1 !== '')));
            if (section !== 'autoship_edit') {
                setDefaultValues((prv) => ({ ...prv, ...PICK_ADDRESSES }))
            }
        }
    }, [allUserData?.emailAddress]);

    function _selectedDefaulAddress(selectAddress) {
        setAddressesObject((pre) => ({ ...pre, shippingAddress: selectAddress }));
        let CONVERTED_ADDRESS = userDataPayload(selectAddress);
        setUserFormData(CONVERTED_ADDRESS);
        if (sameAsBillingChecked) {
            _paymentOptionSetBilling(CONVERTED_ADDRESS);
        }
        _setUseDefaultAddressFunction(null, null, CONVERTED_ADDRESS)
    }

    function _handleOpenDialog(row, section) {
        if (!row && section === 'add') {
            setUserFormData({ ...userFormData, shipping_city: "", shipping_phone: "", shipping_street: "", shipping_zipcode: "", shipping_delivery: "" });
            const SELECT_ROW = _.find(addressObject?.addresses, (row) => !row?.address1);
            setOpenDialog(SELECT_ROW?.id);
        } else {
            setOpenDialog(row?.id);
            const BILLING_DETAIL = userDataPayload(_.find(addressObject?.addresses, { id: row?.id }));
            props?.setCountryState((prv) => ({ ...prv, country: BILLING_DETAIL?.shipping_country, state: BILLING_DETAIL?.shipping_state }))
            setUserFormData(BILLING_DETAIL);
        }
    }

    function _handleCloseFunction() {
        setOpenDialog(null);
        setUserFormData({ ...userFormData, shipping_city: "", shipping_phone: "", shipping_street: "", shipping_street2: "", shipping_zipcode: "", shipping_delivery: "" });
    }

    function _deleteAddress(id) {
        const AFTER_DELTED_DATA = _.reject(addressObject?.addresses, { id: id });
        const PAYLOAD = _addressPayload();

        dispatch(customerUpdateApi({
            ...addressObject?.oldData, [_.find(addressObject?.addresses, { id })?.section]: _addressPayload()
        }, 'addressChangeCheckout', allUserData?.customerId, () => {

            const PICK_ADDRESSES = _.pick({ ...addressObject?.oldData, [_.find(addressObject?.addresses, { id })?.section]: _addressPayload() }, ['shippingAddress', 'billingAddress', 'mailingAddress', 'otherAddress1', 'otherAddress2']);

            if (section !== 'autoship_edit') {
                setDefaultValues((prv) => ({ ...prv, ...PICK_ADDRESSES }));
            }
            const ADDRESS_SELECTION = +(addressObject?.shippingAddress?.id === +id) ? userDataPayload(AFTER_DELTED_DATA[0]) : userDataPayload(addressObject?.shippingAddress)
            setUserFormData(ADDRESS_SELECTION);
            setAddressesObject((prv) => ({
                oldData: { ...prv?.oldData, [_.find(prv?.addresses, { id })?.section]: _addressPayload() },
                shippingAddress: (prv?.shippingAddress?.id === id) ? AFTER_DELTED_DATA[0] : prv?.shippingAddress,
                addresses: [...AFTER_DELTED_DATA, { id, ...PAYLOAD }]
            }));
            // _setUseDefaultAddressFunction(null, null, userDataPayload(ADDRESS_SELECTION))

        }));
    }

    function _submitFunctionPop(values) {
        values['shipping_phone'] = values?.shipping_phone?.replaceAll(/[^\w\s]/gi, '');

        const PAYLOAD = _addressPayload(values);
        dispatch(customerUpdateApi({
            ...addressObject?.oldData,
            [_.find(addressObject?.addresses, { id: +openDialog })?.section]: PAYLOAD
        }, 'addressChangeCheckout', allUserData?.customerId, () => {
            setAddressesObject((prv) => ({
                oldData: { ...prv?.oldData, [_.find(prv?.addresses, { id: +openDialog })?.section]: PAYLOAD },
                shippingAddress: { id: +openDialog, ...PAYLOAD },
                addresses: [..._.reject(addressObject?.addresses, { id: openDialog }),
                { id: +openDialog, ...{ firstName: values?.shipping_urname, lastName: values?.shipping_lastname, ...PAYLOAD } }]
            }));
            const PICK_ADDRESSES = _.pick({ ...addressObject?.oldData, [_.find(addressObject?.addresses, { id: +openDialog })?.section]: PAYLOAD }, ['shippingAddress', 'billingAddress', 'mailingAddress', 'otherAddress1', 'otherAddress2']);
            if (section !== 'autoship_edit') {
                setDefaultValues((prv) => ({ ...prv, ...PICK_ADDRESSES }));
            }
            setUserFormData(values);
            if (sameAsBillingChecked) {
                _paymentOptionSetBilling(values);
            }
            setOpenDialog(null);
        }));
    }

    return (
        <AddessOptionStyle className='mainAddress_option'>
            <div className='address_line'>
                <div className='addAddress_class'>
                    <p>Your addresses</p>
                    {_.find(addressObject?.addresses, (row) => !row?.address1) &&
                        <p onClick={() => _handleOpenDialog(null, 'add')}>Ship to a new address</p>
                    }
                </div>
                {(_.filter(addressObject?.addresses, (r) => (r?.countryCode === _.upperCase(storeAssigned()) && r?.address1))?.length > 0)
                    ?
                    <FormControl>
                        <RadioGroup value={String(addressObject?.shippingAddress?.id)} className="radio_main_group" name="radio-buttons-group">
                            {_.map([..._.filter(addressObject?.addresses, (r) => (r?.countryCode === _.upperCase(storeAssigned()) && r?.address1)),
                                // ..._.filter(addressObject?.addresses, (r) => !r?.address1)
                            ], (row) => (
                                <React.Fragment key={'address' + row?.id}>
                                    {(row?.address1) &&
                                        <div className={classNames('radio_section', { 'activeAddress': (addressObject?.shippingAddress?.id === row?.id) })}>
                                            <FormControlLabel onChange={() => _selectedDefaulAddress(row)} value={String(row?.id)} control={<Radio />} label={parse(`
                                            <p>
                                                <strong>${row?.firstName}&nbsp;${row?.lastName}</strong>
                                                <span>${row?.address1}</span>
                                                <span>${row?.city}, ${row?.regionProvState}, ${row?.postalCode}</span>
                                                <span>${row?.countryCode}</span>
                                            </p>
                                            `)} />
                                            <div>
                                                <span className='editIcon' onClick={() => _handleOpenDialog(row, 'edit')}>Edit address</span>

                                                {addressObject?.shippingAddress?.id !== row?.id &&
                                                    <>
                                                        &nbsp;|&nbsp;
                                                        <span className=' editIcon DelIcon' onClick={() => _deleteAddress(+row?.id)} >Delete</span>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }
                                </React.Fragment>
                            ))}
                        </RadioGroup>
                    </FormControl>
                    :
                    <div className='flex gap-[20px] justify-center items-center text-center h-[100px]'>
                        {_.find(addressObject?.addresses, (row) => !row?.address1) &&
                            <>
                                <p
                                    className='cursor-pointer text-[#0171ba] text-sm text-center hover:border-b hover:border-[#0171ba]'
                                    onClick={() => _handleOpenDialog(null, 'add')}> Create a new address</p>
                                <p>Or</p>
                            </>
                        }
                        {((_.filter(addressObject?.addresses, (r) => r?.address1)?.length > 0) && section !== "autoship_edit") &&
                            <p
                                onClick={() => setMarketOpenDialog(true)}
                                className='cursor-pointer text-[#0171ba] text-sm text-center hover:border-b hover:border-[#0171ba]'
                            >Change the market</p>
                        }
                    </div>
                }
                {(_.filter(addressObject?.addresses, (r) => r?.address1)?.length > 0) &&
                    <div className='text-[16px] text-red-500 font-semibold'>{checkoutError}</div>
                }
            </div>
            <DialogComponent opend={!!openDialog} handleClose={() => _handleCloseFunction()} title={(!userFormData?.shipping_city ? "Add" : "Edit") + ' ' + 'Address'} classFor="addessForm">
                <Formik
                    enableReinitialize
                    validationSchema={VALIDATION_SCHEMA}
                    initialValues={userFormData}
                    onSubmit={(values) => _submitFunctionPop(values)}>
                    {(fieldValue) => {
                        const FieldMethod = fieldValue;
                        return (
                            <Form>
                                <AddressSection disabled={false} section="shipping" fieldValue={FieldMethod} {...{ ...props, ...FieldMethod }} />
                                <DialogActions className='button_action'>
                                    <ButtonComponent type='submit' disabled={!!props?.normalAddressError}>Submit</ButtonComponent>
                                </DialogActions>
                            </Form>
                        )
                    }}
                </Formik>
            </DialogComponent>
            <DialogComponent opend={!!marketOpenDialog} handleClose={() => setMarketOpenDialog(null)} title="Change Market" classFor="marketSelect">
                <div className='mt-[10px]'>
                    <p><span>!Warning: </span><span> It is going to empty the card because products and prices are not the same across markets.</span></p>
                </div>
                <div className='relative'>
                    <div className="dropdown w-full max-w-[320px] m-[20px_auto_0px]">
                        <div
                            onClick={(e) => {
                                setStoreDropdownOpen(!storeDropdownOpen);
                            }}
                            className="dropdown-btn flex justify-between py-[8px] border items-center pr-[8px]"
                        >
                            <div>
                                <ReactCountryFlag countryCode={newMarketCode || 'US'} style={{
                                    fontSize: '1em', marginRight: '2px', lineHeight: '2em', marginLeft: '1rem'
                                }} />
                                {_.find(countyObjects, (r) => r?.code === newMarketCode)?.name}
                            </div>
                            <span
                                className={storeDropdownOpen ? "fas fa-caret-up" : "fas fa-caret-down"}
                            />
                        </div>
                        <div
                            className="dropdown-content mt-[3px] h-[160px] overflow-y-scroll shadow-[0px_0px_10px_5px_#dddd]"
                            style={{ display: storeDropdownOpen ? "block" : "none" }}
                        >
                            {_.map([
                                ..._.reverse(_.filter(countyObjects, (row) => _.includes(['US', 'CA'], row?.code))),
                                ..._.reject(countyObjects, (row) => _.includes(['US', 'CA'], row?.code))],
                                (row) => (
                                    <div
                                        key={row?.name}
                                        onClick={(e) => {
                                            setNewMarketCode(row?.code);
                                            setStoreDropdownOpen(!storeDropdownOpen);
                                        }}
                                        className="item pl-[10px] py-[4px] cursor-pointer hover:bg-[#fcfcfced]"
                                    >
                                        <ReactCountryFlag className="" countryCode={row?.code} style={{ fontSize: '1em', marginRight: '4px', lineHeight: '2em' }} />
                                        {row?.name}
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                <DialogActions className='mt-4 action_button w-full max-w-[180px] text-center m-[0_auto]' >
                    <button className="w-full max-w-[80px] p-[10px] bg-[#0071BA] text-[#fff] rounded-[16px]" onClick={() => setMarketOpenDialog(null)}>Cancel</button>
                    <button className="w-full max-w-[80px] p-[10px] bg-[#0071BA] text-[#fff] rounded-[16px]" onClick={() => {
                        setMarketOpenDialog(null);
                        _.map(['normal', 'retail', 'preferred', 'IBO'], (row) => {
                            localStorageCall().removeItem(String(`${row}Products`));
                            localStorageCall().setItem('store', newMarketCode);
                            setNewMarketCode(newMarketCode)
                        })
                        setTimeout(() => {
                            window.location.assign(`/${_.lowerCase(newMarketCode)}`)
                        }, 700)
                    }}>Ok</button>
                </DialogActions>
            </DialogComponent>
        </AddessOptionStyle >
    );
}

export default withRouter(AddressOptions);