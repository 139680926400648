import React, { useEffect } from 'react'
import IncentiveSection from './IncentiveSection'
import localStorageCall from '../../services/Methods/localstorage.hook';
import { useNavigate } from 'react-router-dom';
import { storeAssigned } from '../../services/Methods/normalMethods';

function IncentivePage() {
    const isUserLogin = localStorageCall().getSingleItem('Token');
    const navigate = useNavigate();
    useEffect(() => {
        if (!isUserLogin) {
            navigate(`/${storeAssigned()}/login?redirectTo=/you-share-they-share`);
        }
    }, []);

    return (
        <div><IncentiveSection /></div>
    )
}

export default IncentivePage;