import React from "react";
// import About3 from '../assets/about4.png';
import About3 from '../assets/about4_new.png';


const AboutThirdSection = (props) => {
  const { ABOUT_TEXTS } = props;
  return (
    <>

      <div className="flex flex-wrap">
        <div className="md:w-1/2 w-full min-h-[240px] bg-cover bg-center bg-no-repeat " style={{ backgroundImage: `url(${About3})` }}>
        </div>
        <div className="md:w-1/2 w-full py-9 md:px-9 px-[15px]">
          <div className="lg:py-[36px] max-w-[560px] mx-auto">
            <h2 className="text-[28px] leading-[1.2] font-bold text-heading-color mb-[12px] pb-4">
              {ABOUT_TEXTS?.pro_vision}
            </h2>
            <p className="mb-4 text-base text-content-color pb-4">
              {ABOUT_TEXTS?.our_vision}
            </p>
          </div>
        </div>
      </div>

    </>
  )
}

export default AboutThirdSection;