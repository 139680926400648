import React, { useEffect, useRef, useState } from 'react';
import insentiveGift from "./assets/insentiveGift.png";
import DialogComponent from "../../Inputs/DailogComponent";
import styled from 'styled-components';
import localStorageCall from '../../services/Methods/localstorage.hook';
import { ContactUsApi, getCustomerRefferal } from '../../services/Redux/Reducer/CustomerSlice';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { SuccessDisplay, _setTimeOut } from '../../services/Methods/normalMethods';
import ButtonComponent from '../../Inputs/ButtonComponent';


const IncentiveFormStyle = styled.div`
&.main_form {
    input {
        border: 1px solid #0071BA;
        padding: 10px 12px;
        width: 100%;
        max-width: unset;
        

        &:focus-visible{
            outline: none;
        }
        &:disabled {
            border: 1px solid #ddd;
        }
    }

    /* h3 {
        margin-bottom: 20px;
        font-size: 22px;
        font-weight: 600;
        color: #0071BA;
    } */
    
    button {
        border: 1px solid;
        width: 270px;
        padding: 10px;
        background: #0071BA;
        font-size: 18px;
        color: #fff;

        &:hover {
            background: transparent;
            color: #0071BA;

        }
    }

    ul {
        background: #fff;
        width: 100%;
        text-align: left;
        z-index: 999;
        height: auto;
        max-height: 200px;
        overflow-y: scroll;

        &.hide {
            display: none;
        }
    }
}

`;

const useDebounce = (state, delay = 1000) => {
    const [debounce, setDebounce] = useState(state);

    useEffect(() => {
        const timeOut = setTimeout(() => {
            setDebounce(state)
        }, delay);

        return () => clearTimeout(timeOut);

    }, [state, delay])

    return debounce
}

const InputIncentive = ({ children,
    placeholder, name, value, onChange, lisOfData, setListOfUsers, selectedUsers, setSelectedUsers, errors, setErrors
}) => {

    const ref = useRef(null);

    // useOutsideAlerter(ref, () => {
    //     setListOfUsers([]);
    // })

    return (
        <div className='w-full relative' >
            <div className='w-full '>
                <input type="text" {...{ placeholder, name, value }} className='' onChange={(e) => onChange(name, e.target.value)} />
                {errors[name] && <p className='text-[#f00] text-sm text-left pl-[4px]'>{errors[name]}</p>}
            </div>

            <ul className={`absolute ${lisOfData?.[name]?.length > 0 ? 'show' : 'hide'}`}>
                {(lisOfData?.[name]?.length > 0)
                    ?
                    _.map(lisOfData?.[name], (r) => (
                        <li
                            className='cursor-pointer py-2 px-4'
                            key={r?.customerId}
                            onClick={() => {
                                if (!_.find(selectedUsers, { customerId: r?.customerId })) {
                                    onChange(name, String(r?.publicProfile?.fullName + ',' + r?.customerId), 'onClick')
                                    setSelectedUsers((prv) => ({ ...prv, [name]: r }));
                                } else {
                                    setErrors((prv) => ({ ...prv, [name]: 'Already selected' }));
                                    _setTimeOut(() => setErrors(''), 2000);
                                    setListOfUsers({});
                                }
                            }}>
                            {r?.publicProfile?.fullName}, {r?.customerId}
                        </li>

                    )) :
                    <li>No record found</li>
                }
            </ul>
        </div>
    )
}

function IncentiveSection(props) {
    const dispatch = useDispatch();

    const customerType = localStorageCall().getItem('customerTypeDescr');

    const [currentName, setCurrentName] = useState('');
    // const [loader, setLoader] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [fieldsData, setFieldData] = useState({
        fieldOne: "",
        fieldTwo: "",
        fieldThree: "",
        fieldFour: "",
        // fieldFive: "",
        // fieldSix: ""
    });
    const [errors, setErrors] = useState(JSON.parse(JSON.stringify(fieldsData)));
    const [selectedUsers, setSelectedUsers] = useState(JSON.parse(JSON.stringify(fieldsData)));

    const [listOfUsers, setListOfUsers] = useState({});
    const debounceValue = useDebounce(fieldsData)
    const [successMsg, setSuccessMsg] = useState({ open: false, msg: '' });
    const [showTheySharePopUp, setShowTheySharePopUp] = useState(false);

    useEffect(() => {
        const values = _.compact(_.values(debounceValue));
        if (values?.length > 0) {
            let ID = customerType?.userData?.customerId;
            // if (_.includes(["fieldThree", "fieldFive"], currentName)) {
            //     ID = selectedUsers['fieldOne']?.customerId;
            // } else if (_.includes(["fieldFour", "fieldSix"], currentName)) {
            //     ID = selectedUsers['fieldTwo']?.customerId;
            // }
            _getReferralData(debounceValue[currentName], currentName, ID);
        }
    }, [debounceValue])

    // 18572,Rob Wyman,   22148
    // 18575,18578,           22163
    function _getReferralData(value, name, id = customerType?.userData?.customerId) {
        // setLoader(true);
        dispatch(getCustomerRefferal((data) => {
            if (data?.length === 0) {
                setListOfUsers({});
            } else {
                setListOfUsers({ [name]: data });
            }
            // setLoader(false);
        }, value, 'incentive', id));
    }

    function InputCommon(placeholder, name, label) {
        return (
            <div className='w-full'>
                <label htmlFor={name} className='block text-left mb-1 font-semibold'>{label}</label>
                <InputIncentive {...{
                    placeholder, name,
                    lisOfData: listOfUsers, setListOfUsers,
                    selectedUsers, setSelectedUsers,
                    errors, setErrors,
                    value: fieldsData[name],
                    onChange: (name, value, action = "onChange") => {
                        setFieldData({ ...fieldsData, [name]: value });
                        setCurrentName(name);
                        if (value === "") {
                            setSelectedUsers({ ...selectedUsers, [name]: '' })
                        } else {
                            setErrors({ ...errors, [name]: "" })
                        }
                        if (action === "onClick") {
                            setListOfUsers({})
                        }
                    }
                }} />
            </div>
        )
    }

    function _handleSubmit(res) {
        let isValid = true;
        let error = {}
        for (const [key, value] of _.entries(_.pick(selectedUsers, ['fieldOne', 'fieldTwo']))) {
            if (_.isEmpty(value)) {
                error = { ...error, [key]: 'this field is required' };
                isValid = false;
            }
            // else {
            //     isValid = true;
            //     if (!(_.isEmpty(selectedUsers?.fieldThree) && _.isEmpty(selectedUsers?.fieldFour))) {
            //         setShowTheySharePopUp(_.eq(res, 'pop_submit') ? false : true);
            //         isValid = _.eq(res, 'pop_submit') ? true : false;
            //     }
            // }
        }

        if ((_.isEmpty(selectedUsers?.fieldThree) && !_.isEmpty(selectedUsers?.fieldFour)) || (!_.isEmpty(selectedUsers?.fieldThree) && _.isEmpty(selectedUsers?.fieldFour)) && isValid) {
            setShowTheySharePopUp(_.eq(res, 'pop_submit') ? false : true);
            isValid = _.eq(res, 'pop_submit') ? true : false;
        }


        setErrors(error);

        if (isValid) {
            //api call        
            const Data = {
                firstname: customerType?.userData?.publicProfile?.firstName,
                lastname: customerType?.userData?.publicProfile?.lastName,
                email: customerType?.userData?.emailAddress,
                description: `
                ${customerType?.userData?.publicProfile?.fullName} (${customerType?.userData?.customerId}) is reporting completion of You Share, They Share
                You Share:
                Right:
                    ${selectedUsers['fieldTwo']?.publicProfile?.fullName} (${selectedUsers['fieldTwo']?.customerId})
                Left:
                    ${selectedUsers['fieldOne']?.publicProfile?.fullName} (${selectedUsers['fieldOne']?.customerId})

                They Share:
                Right:
                    ${selectedUsers['fieldFour'] && selectedUsers['fieldFour']?.publicProfile?.fullName + ' ' + (selectedUsers['fieldFour']?.customerId)}
                Left:
                    ${selectedUsers['fieldThree'] && selectedUsers['fieldThree']?.publicProfile?.fullName + ' ' + (selectedUsers['fieldThree']?.customerId)} 
            `
            };
            // ${selectedUsers['fieldSix'] && selectedUsers['fieldSix']?.publicProfile?.fullName + ' ' + (selectedUsers['fieldSix']?.customerId)} 
            // ${selectedUsers['fieldFive'] && selectedUsers['fieldFive']?.publicProfile?.fullName + ' ' + (selectedUsers['fieldFive']?.customerId)} 
            dispatch(ContactUsApi(Data, (status) => {
                setSuccessMsg({ open: true, msg: "Your ticket has been submitted " });
                setTimeout(() => {
                    setSuccessMsg({ open: false, msg: "" });
                    setOpenPopup(false);
                    window.location.reload();
                }, 6000);
            }));
        }
    }


    const ButtonClass = `xl:mb-4 lg:mb-3 md:mb-2 sm:mb-1.5 mb-3 text-white f-q-regular font-medium text-[16px] hover:bg-opacity-90 transition-all duration-200 py-[10px] px-[20px] border-2 bg-button-color border-button-color 
       text-white hover:bg-white hover:text-button-color w-full max-w-[150px] font-semibold`;

    return (
        <React.Fragment>
            <div className="relative !flex items-center bg-gradient-to-r from-[#98f0fd] to-[#f67cce]">
                {/* <figure className={`absolute top-0 left-0 w-full h-full z-10`} style={{ backgroundImage: `url(${InsentiveBackground})` }}></figure> */}
                <div className="container max-w-[1260px] mx-auto px-[15px] relative z-20">
                    <div className="text-center pb-30px flex items-center max-lg:flex-wrap justify-between max-lg:pt-[10px] max-lg:pb-[50px]">
                        <div className={`lg:max-w-[814px] w-full max-lg:order-2`}>
                            <h1 className={`xl:text-[70px] lg:text-[55px] sm:text-[40px] text-[31px] lg:leading-[81px] sm:leading-[1.5] leading-[1.3] font-semibold text-white`} >
                                <span className="font-semibold">You Share,</span>
                                <span className="font-normal relative">
                                    They Share
                                    <span className="absolute xl:top-[14px] lg:top-[5px] sm:top-[0px] top-[unset] max-sm:bottom-[30px] right-0 bg-gradient-to-r from-[#3a2bdd] to-[#8dc1f0] uppercase font-semibold md:text-[12px] text-[10px] leading-[1] md:p-[3px_10px] p-[3px_5px] rounded-[5px] text-center">Promo</span>
                                </span>
                            </h1>
                            <h2 className="block xl:text-[24px] lg:text-[20px] md:text-[18px] text-[16px] font-normal text-[#2f302e]">Promo period:
                                <span className="font-semibold"> June 1, 2024 - September 30, 2024</span>
                            </h2>
                        </div>
                        <figure className="block xl:max-w-[400px] md:max-w-[300px] max-w-[200px] max-lg:mx-auto w-full max-lg:order-1">
                            <img src={insentiveGift} alt="Image" className='block aspect-square h-full w-full' />
                        </figure>
                    </div>
                </div>
            </div>

            <IncentiveFormStyle className='w-full main_form py-[100px] container mx-auto px-[15px]'>
                <div className='w-full relative items-center mx-auto text-center'>
                    <div className='w-full flex justify-center text-center mb-8'>
                        <p className='font-semibold text-[22px]'>
                            {(customerType?.userData?.publicProfile?.fullName || '')}
                            <span className='block text-[16px] mt-2'>ID #{(customerType?.userData?.customerId || '')}</span>
                        </p>
                    </div>
                    <div className='w-full flex flex-col items-center'>
                        <div className='w-full mb-8 bg-[#ddd] max-w-[50%] rounded-[20px] md:p-5 p-[15px_15px_20px]'>
                            <div className="block lg:max-w-[100%] mx-auto">
                                <h3 className='text-[22px] mb-3 font-semibold text-[#0071BA]'>You Share</h3>
                                <div className='flex lg:gap-[26px] md:gap-[16px] gap-[12px] max-lg:flex-wrap w-full justify-between relative'>
                                    {InputCommon("Left Person's Name & ID", "fieldOne", 'Left')}
                                    {InputCommon("Right Person' s Name & ID", "fieldTwo", 'Right')}
                                </div>
                            </div>
                        </div>
                        <div className='w-full mb-8 bg-[#ddd] max-w-[50%] rounded-[20px] md:p-5 p-[15px_15px_20px]'>
                            <div className="block lg:max-w-[100%] mx-auto">
                                <h3 className='text-[22px] mb-3 font-semibold text-[#0071BA]'>They Share</h3>
                                <div className='flex lg:gap-[26px] md:gap-[16px] gap-[12px] max-lg:flex-wrap w-full justify-between relative after:content-[""] after:h-[100%] after:top-0 after:left-[50%] after:-translate-x-[50%] after:border-r-[1px] after:border-[#000] after:absolute max-lg:after:hidden'>
                                    <div className='w-full flex gap-[12px] max-[575px]:flex-wrap'>
                                        {InputCommon("Personally Sponsored You  Share Left", "fieldThree", 'Left')}
                                        {/* {InputCommon("Personally Sponsored You Share Right", "fieldFive", 'Right')} */}
                                    </div>
                                    <div className='w-full flex gap-[12px] max-[575px]:flex-wrap'>
                                        {InputCommon("Personally Sponsored You  Share Right", "fieldFour", 'Right')}
                                        {/* {InputCommon("Personally Sponsored You Share Right", "fieldSix", 'Right')} */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <button className='' type='button' onClick={() => _handleSubmit()}>Submit</button>
                </div>
            </IncentiveFormStyle>
            <DialogComponent opend={showTheySharePopUp} handleClose={() => setShowTheySharePopUp(false)} title="Confirmation" classFor="success_incentive">
                <p className='flex my-[15px] gap-[12px]'>
                    <strong className='text-xl '>Note:</strong>
                    <span className='font-semibold text-black-500'>To qualify for the <b>They Share Promo</b> you must enter two names.</span>
                </p>
                <div className='action_button flex gap-x-[10px] justify-center'>
                    <button type='button' className={ButtonClass} onClick={() => setShowTheySharePopUp(false)}>Add Name</button>
                    <button type='button' className={ButtonClass} onClick={() => _handleSubmit('pop_submit')}>Submit Anyway</button>
                </div>
            </DialogComponent>
            <DialogComponent opend={successMsg?.open} handleClose={() => setSuccessMsg({ open: false, msg: "" })} title="Success" classFor="success_incentive">
                <p className='mt-[12px]'>
                    <span className='font-semibold text-green-500'>{successMsg?.msg}</span>
                </p>
                <button className='successfull-msg' onClick={() => {
                    setSuccessMsg({ open: false, msg: "" });
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000)
                }
                }>ok</button>
            </DialogComponent>
        </React.Fragment >
    )
}

export default IncentiveSection;