import React from 'react';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { FloatValues, storeAssigned, _productPrice } from "../../../services/Methods/normalMethods";
import _ from "lodash";
import parse from "html-react-parser";
import ButtonComponent from '../../../Inputs/ButtonComponent';
import { QuantityComponent } from '../../../services/Methods/commonPayload';
import Products from '../assets/product-ingr.png';

const MainSingleProduct = ({
    swiper, setSwiper,
    index, setIndex,
    singleProductData, extraObjects,
    selectedPrice, setSelectedPrice,
    AllProductAndCheckoutText, COMMON_TEXTS,

    _IncreaseDecreaseFunction, _addToCart
}) => {

    const {
        singleOrder_text, smartshipOrder_text, manufature_in, singleProductPageDiscription, CGMP_compliant, NSF_certified,
        FDA_registered, manufature_desc, singleProductPageDiscription_text
    } = AllProductAndCheckoutText;
    const reversedImages = extraObjects && [...extraObjects?.images].reverse();
    return (
        <>
            <div className="flex items-center text-light-text space-x-1 sm:space-x-0.5 md:space-x-1 lg:space-x-1.5 f-q-bold text-sm sm:text-[8px] md:text-[10px] lg:text-[12px] xl:text-[15.5px] container mx-auto lg:px-3.5 sm:py-5 px-[15px] sm:px-0 xl:mt-0 mt-4 xl:mb-3 mb-0 sm:mb-7 sm:mt-5 md:mb-10 md:mt-7 lg:mb-14 lg:mt-10 xl:mb-16 xl:mt-6">
                <Link to={`/${storeAssigned()}/shop`}>Shop</Link>
                <Link
                    to={`/${storeAssigned()}/product`}
                    className="text-black f-q-extrabold"
                >
                    {extraObjects?.title}
                </Link>
            </div>
            <div className="container mx-auto mb-12 sm:mb-10 md:mb-12 lg:mb-14 xl:mb-16 flex flex-wrap items-start lg:px-3.5  py-5 sm:py-5 px-[15px]">
                <div className="w-full lg:w-[77px] xl:w-[98px] lg:mr-2.5 xl:mr-3 shrink-0 xl:order-1 lg:order-1 order-2 xl:mt-0 lg:mt-0 mt-4">
                    {_.map(reversedImages, (image, i) => (
                        <img
                            key={"image" + i}
                            onClick={() => {
                                setIndex(i);
                                swiper.slideTo(i);
                            }}
                            src={image}
                            className={`inline-block object-cover lg:w-full w-[80px] h-[80px] xl:h-[100px] lg:h-[80px] border lg:mb-1 lg:mr-2 mr-1
                            cursor-pointer ${index == i ? "border-black" : ""
                                }`}
                            alt=""
                        />
                    ))}
                </div>
                <div className="md:p-5 p-3 px-0 sm:px-0 lg:w-[400px] xl:w-[600px] w-full border flex items-center justify-center shrink-0 xl:order-2 lg:order-2 order-1 product-cstm-slider">
                    <Swiper onSwiper={(e) => setSwiper(e)} onSlideChange={(e) => {
                        setSwiper(e);
                        setIndex(e.activeIndex);
                    }}
                    >
                        {_.map(reversedImages, (image, i) => (
                            <SwiperSlide key={i} className="!h-full self-center">
                                <div className="flex items-center justify-center xl:h-[500px] h-[350px]">
                                    <img src={image} className="object-contain h-full w-full" alt="" />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className="xl:w-[calc(100%-730px)] lg:w-[calc(100%-487px)] xl:ml-[20px] lg:px-8 px-0 order-3 xl:mt-0 mt-6">
                    <h2 className="f-besporh text-black text-3xl sm:text-8 md:text-8 lg:text-8 xl:text-8 font-bold text-[#373f50] leading-tight mb-2">
                        {extraObjects?.title}
                    </h2>
                    <div className="f-q-regular sm:text-[9.7px] md:text-[11.7px] lg:text-[15.5px] xl:text-[19.3px] leading-tight">
                        {extraObjects?.otherFields?.longField2 && parse(extraObjects?.otherFields?.longField2)}
                    </div>
                    <h5 className="font-semibold mb-4 !mt-0 text-xl text-[#373f50] leading-tight">
                        ${FloatValues(singleProductData?.normal_oneTime)} | ${FloatValues(singleProductData?.normal_autoship)} with SmartShip
                    </h5>
                    {/* <div className="flex items-center">
                        <div className="flex mr-2 sm:mr-1 lg:mr-1.5">
                            {Array.from({ length: 5 }).map((_ar, i) => (
                                <BsStarFill
                                    key={i}
                                    className="text-[#f586a3] sm:text-[10.5px] md:text-[13px] lg:text-[17.5px] xl:text-[20.5px] mr-1 sm:mr-0.5 xl:mr-1"
                                />
                            ))}
                        </div>
                        <p className="f-q-regular font-bold sm:text-[9.5px] md:text-[11.5px] lg:text-[15.5px] xl:text-[19px]">
                            {`${filteredReviews.length} reviews`}
                        </p>
                    </div> */}
                    {singleProductData?.isEligibleForAutoOrder &&
                        <div className="md:ml-1 sm:pb-0 pb-4">
                            {_.map(['oneTime', 'autoship'], (row) => (
                                <React.Fragment key={row}>
                                    <div className="flex items-center py-4 w-full max-w-[350px] justify-between">
                                        <div className='flex'>
                                            <input
                                                type="radio"
                                                className="sm:scale-75 lg:scale-110 xl:scale-125 mr-2 sm:mr-1 lg:mr-2.5 xl:mr-4 accent-button-color"
                                                name={row}
                                                onChange={() => setSelectedPrice(row)}
                                                checked={selectedPrice === row}
                                                id="option1"
                                            />
                                            <label
                                                htmlFor="option1"
                                                className="cursor-pointer text-sm sm:text-[9px] md:text-[10px] lg:text-[13px] xl:text-[16px] f-q-regular"
                                            >
                                                {row === "oneTime" ? singleOrder_text : smartshipOrder_text}
                                            </label>
                                        </div>
                                        <div className='mb-0 text-button-color font-weight-normal'>${FloatValues(singleProductData?.[`normal_${row}`])}</div>
                                    </div>
                                    {(row === 'oneTime') &&
                                        <div className="text-button-color h-[2px] w-full max-w-[350px] my-1.5 sm:my-1 bg-button-color"></div>
                                    }
                                </React.Fragment>
                            ))}
                        </div>
                    }
                    <div className="flex space-x-3 sm:space-x-1.5 md:space-x-2 lg:space-x-3 xl:space-x-4 ">
                        <QuantityComponent row={singleProductData} _minCartQuantityIncDec={_IncreaseDecreaseFunction} />
                        <ButtonComponent
                            classes="sm:!w-[97px] md:h-[35px] md:!w-[115px] lg:h-[42px] lg:!w-[155px] xl:!w-[190px] lg:text-sm h-[40px] text-black"
                            onClick={() => _addToCart(+singleProductData?.id)}
                        >
                            {COMMON_TEXTS?.addToCart_text}
                        </ButtonComponent>
                    </div>
                    <div className="listing_style f-q-regular sm:text-[9.7px] md:text-[11.7px] lg:text-[15.5px] xl:text-[19.3px] leading-tight">
                        {extraObjects?.otherFields?.longField1 && parse(extraObjects?.otherFields?.longField1)}
                    </div>
                </div>
            </div>
            <div className="bg-button-color text-white py-8">
                <div className="container mx-auto max-w-[1260px] px-[15px]">
                    <h5 className="font-semibold text-[1.25rem] text-white mb-[1.5rem]">{singleProductPageDiscription_text}</h5>
                    <div className="text-[.9375rem]">
                        {_.map(singleProductPageDiscription, (row, index) => (
                            <p key={index + 'dis'} className="m-0 mb-4">{parse(row)}</p>
                        ))}
                    </div>
                </div>
            </div>
            <div className="container m-auto px-[15px] pb-8 ">
                <div className="text-center ingr-product">
                    <img src={Products} alt="Product" className="w-full max-w-[500px] mt-[40px] mb-[20px] h-auto mx-auto" />
                    <p>
                        {manufature_in} <a href={CGMP_compliant?.link} target="_blank">{CGMP_compliant?.text}</a>.
                        {manufature_in} <a href={NSF_certified?.link} target="_blank">{NSF_certified?.text}</a>.<br />
                        {_.replace(manufature_in, 'a', 'an')} <a href={FDA_registered?.link} target="_blank">{FDA_registered?.text}</a>.
                        {manufature_desc}
                    </p></div></div>
        </>
    )
}

export default MainSingleProduct;







