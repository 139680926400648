import React from 'react';

import { HomeTextProducts } from '../../TextConfigs/HomeText';
import withRouter from '../../services/HOC/withRouterHOC';
import { storeAssigned } from '../../services/Methods/normalMethods';
import withProductHOC from '../../services/HOC/withProductHOC';
import _ from 'lodash';
import { Link } from 'react-router-dom';

const Products = ({ navigate, ROUTING_TEXT, COMMON_TEXTS, allProductState, section }) => {
  const CURRENT_STORE = _.upperCase(storeAssigned()) || 'US';

  return (
    <div className="lg:py-[100px] py-[70px]">
      <div className="container mx-auto max-w-[1260px] px-[15px]">
        <div className="-mx-15px flex flex-wrap">
          {/* {_.map(_.filter(allProductState, (r) => _.includes(['PRVGSH1060US', 'PVZ-6-002-5-20US', 'PVZCOMBO140US', 'PVZSAMPLE-1US'], r?.itemCode)), (row) => ( */}
          {_.map(_.filter(allProductState, (r) => _.includes(['PRVGSH1060US', 'PVZ-6-002-5-20US', 'PVZCOMBO140US', 'PVZSAMPLE-1US', 'PVZ-7-003-4-01US'], r?.itemCode)), (row) => (
            <Link className='lg:w-1/4 sm:w-1/2 w-full px-[15px]' key={row?.id} to={`/${storeAssigned()}/shop/${row?.id}/${_.lowerCase(row?.title)?.replaceAll(/ /g, "_")}`}>
              <HomeTextProducts imgSrc={row?.image} altText={"Product" + row?.id} productName={row?.title} prices={{
                normalAutoship: row?.normal_autoship,
                normalOneTime: row?.normal_oneTime,
                CURRENT_STORE: CURRENT_STORE
              }} />
            </Link>
          ))}
        </div>
        <div className="w-full px-[15px]">
          <div className="flex flex-wrap sm:justify-around justify-center sm:flex-row flex-col items-center sm:gap-0 gap-4">
            <button
              className="flex flex-wrap items-center justify-center rounded-[50px] bg-button-color px-[25px] py-[18px] lg:text-[19px] text-base font-medium text-white leading-[1.5] border-2 border-button-color  xl:w-1/4 sm:w-1/2 w-full lg:max-w-[300px] max-w-[250px] hover:bg-white hover:text-button-color transition-all ease-in-out duration-300"
              onClick={() => navigate(`/${storeAssigned()}/${ROUTING_TEXT?.Shop_routingName}`)}
            >
              {COMMON_TEXTS?.byNow_text}
              <span className='ml-[10px] leading-[1]'><i className="fas fa-arrow-right"></i></span>
            </button>
            <button
              className="flex flex-wrap items-center justify-center rounded-[50px] bg-button-color px-[25px] py-[18px] lg:text-[19px] text-base font-medium text-white leading-[1.5] border-2 border-button-color  xl:w-1/4 sm:w-1/2 w-full lg:max-w-[300px] max-w-[250px] hover:bg-white hover:text-button-color transition-all ease-in-out duration-300"
              onClick={() => navigate(`/${storeAssigned()}/${ROUTING_TEXT?.signup_routingName}`)}
            >
              {COMMON_TEXTS?.createAccountSave_text}
              <span className='ml-[10px] leading-[1]'><i className="fas fa-arrow-right"></i></span> </button>
          </div>
        </div>
      </div>
    </div>
  );
};

Products.defaultProps = {
  section: 'normal'
}
export default withRouter(withProductHOC(Products));
